import React, { useState } from 'react'
import uuid from 'react-uuid'


const galleris = [
    "images/home/gallery/gal1.png",
    "images/home/gallery/gal2.png",
    "images/home/gallery/gal3.png",
    "images/home/gallery/gal4.png",
    "images/home/gallery/gal5.png",
    "images/home/gallery/gal6.png",
    "images/home/gallery/gal7.png",
    "images/home/gallery/gal8.png",
    "images/home/gallery/gal9.png"


]

function Gallery() {

  const [imageSource, setImageSource] = useState(0)

  const zoomImage = (index) => (e)=> {
    // console.log(parseInt(e.target.src.slice(-5).split('.')[0]))
    // console.log(e.target.src.slice(-6).split('.')[0])
    setImageSource(index)
 
}


  return (

    <section class="pt-10 max-w-screen-lg mx-auto p-4">
        <h1 className='text-3xl font-bold'>Gallery </h1>
        <div class="pt-4">
            <div class="grid grid-cols-2 md:grid-cols-3 gap-3 img-zoom ">
                {galleris.map((gal,index) => <img onClick={zoomImage(index+1)}
                        key={uuid()} className={` ${index ==0 ? "md:col-span-1 col-span-2":""} hover:cursor-zoom-in object-cover object-center w-full h-full rounded-lg`}
                                            src={gal}  />
                                  )}
               
            </div>
        </div>
        {imageSource !== 0 && imageSource !== 10 && 
                <>
                    <div onClick={()=> setImageSource(0)} class="fixed inset-0 bg-white backdrop-blur-sm bg-opacity-75 transition-opacity"></div>
                     <div class="fixed inset-0 z-50 overflow-y-auto">
                        <div class="flex  min-h-full max-w-screen-md mx-auto items-center justify-center p-4 text-center  sm:p-0">
                            
                            <div class="relative transform overflow-hidden rounded-lg text-left shadow-xl transition-all sm:my-8 sm:min-w-full sm:max-w-lg">
                               
                                <div className=' '>
                                    <button className='text-black float-right ' onClick={() => setImageSource(0)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                    </button>
                                    <button onClick={()=> setImageSource(imageSource => imageSource +1)} className='absolute  bg-white  hover:bg-opacity-90 px-4 py-3 right-0 top-1/2'>
                                        Next  
                                    </button>
                                    <button onClick={()=> setImageSource(imageSource => imageSource -1)} className='absolute  bg-white  hover:bg-opacity-90 px-4 py-3 left-0 top-1/2'>
                                        Prev
                                    </button>
                                    {/* {imageSource} */}
                                    <img src={`images/home/gallery/gal${imageSource}.png`} className="rounded-md min-w-full" alt="" />
                                 
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </>
             }
    </section>
  )
}

export default Gallery