import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

const projects_data = [
    {
        id:1,
        slug:"ouarialt-rural-learning-center",
        title:"Ouarialt Rural Learning Center",
        image:"Ouarialt Rural Learning Center",
        intro:["The Berber village of Ouarialt is situated south west from Marrakech; it is roughly an hour and half drive including a dirt track section. The village is beautifully clung to the arid steep slopes and overhangs a lush valley of organized farming terraces. Subsistence Agriculture and livestock remain the main economic resources of this Berber community.",'Childreach Int organization and Travel Link have both contributed to the building of a multifunctional centre benefiting a great number of children and women, about 20 kids and 40 women attend the classes.'],
        obj_title:"The objectives of Ouarialt village centre",
        objectifs :[
            "Create learning centre in this village and provide a high quality education for children.",
            "Create sustainable opportunities in favour of rural women.",
            "Raise awareness of the ressources and heritage of this rural area.",
            "Focus on health, education and environmental protection in this village.",
            "Enhance the quality of living in rural areas and support local businesses."
        ],
        statistics:[
            {title:"Brief summary of work carried out & which year(s) the project work took place:",
                statistics :[
                   "1- Opening date: September 17, 2014.",
                   "2- Completion of work: Interior and exterior painting, electricity and plumbing, floor tile, waterproofing and exterior enclosure.",
                   "3- Participation of Big Built Morocco: 03 Groups"
                ] },
            {title:"Number of children directly impacted/number of indirect beneficiaries:",
                statistics :[
                   "1- Children directly impacted: From 2014 to 2017: 54 (children under the age of 06).",
                   "2- Number of students: 48 (primary and secondary school students, support courses computer basic, foreign language French and English.",
                   "3- Number of indirect beneficiaries: Village community."
                ] },
            {title:"Any current statistics/ ranking information/achievements of the centre/pupils:",
                statistics :[
                   "Current statistics: 107",
                   "1- Year school: 2013/2014: 54",
                   "2- Year school: 2014/2015: 17",
                   "3- Year school: 2015/2016: 24",
                   "4- Year school: 2016/2017: 12",

                ] },
        ]

    },
    {
        id:2,
        slug:"tighratine-rural-learning-center",
        title:"Tighratine Rural Learning Center",
        image:"Ouarialt Rural Learning Center",
        intro:[
            'Ait Hkim is one of the stunning valleys in the heart of the high Atlas Mountains; situated at 1600m .There are a set of scattering Berber villages totally inhabited by Berber communities whose main works are farming and livestock.',
            'Childreach Int and Travel Link have initiated an educational centre in 2013, through a succession of volunteering groups taking part in various building stages. The centre has currently been open (still needs power and pipe water), benefits a great number of children and women.',
            'You will need a day journey to reach this spectacular valley with a Short driving detour from Tichka road (Road connecting Marrakech to Ouarzazate) to get to Ait Chraa village, whose inhabitants will warmly receive you and let you know about their simple way of life .'
            ],
        obj_title:"The objectives of Tighratine village centre :",
        objectifs :[
            "Create learning centre in this village and provide a high quality education for children.",
            "Create sustainable opportunities in favour of rural women.",
            "Raise awareness of the ressources and heritage of this rural area.",
            "Focus on health, education and environmental protection in this village.",
            "Enhance the quality of living in rural areas and support local businesses."
        ],
        statistics:[
            {title:"Brief summary of work carried out & which year(s) the project work took place:",
                statistics :[
                   "1- Opening date: February 02, 2014.",
                   "2- Completion of work: Interior and exterior painting, electricity and plumbing, floor tile, waterproofing and exterior enclosure.",
                   "3- Participation of Big Built Morocco:03 Groups"
                ] },
            {title:"Brief summary of work carried out & which year(s) the project work took place:",
                statistics :[
                   "Current statistics:",
                   "1- Year school: 2013/2014: 64",
                   "2- Year school: 2014/2015: 30",
                   "3- Year school: 2015/2016: 24",
                   "4- Year school: 2016/2017: 30",

                ] },

            {title:"Any current statistics/ ranking information/achievements of the centre/pupils :",
                statistics :[
                   "Current statistics:",
                   "Improvement of learning ability.",
                   "Smooth school to primary school.",
                   "Easy access to primary school.",
                ] },
        ]
    },

    {
        id:3,
        slug:"taourirt-rural-learning-center",
        title:"Taourirt Rural Learning Center",
        image:"Taourirt Rural Learning Center",
        intro:[
            'Located south from Marrakech at the foot of the Atlas Mountains; Taourirt is around one hour drive on road from Marrakech in direction of Tahannaout; Ouraken . The Inhabitants are Berber people, living from terraced farming and a wide livestock.',
            'Team Seefried Germany and Travel Link have set up a charitable kindergarten be available in. There are about 19 kids.',
            'We can organize a day trip to enjoy the pretty scenery of the foothills. You will be also able to explore the village, visit the educational centre, and finally share a glass of mint tea with a local family before you head back to Marrakech.'
        ],
        obj_title:"The objectives of Taourirt village centre :",
        objectifs :[
            "Create learning centre in this village and provide a high quality education for children.",
            "Create sustainable opportunities in favour of rural women.",
            "Raise awareness of the ressources and heritage of this rural area.",
            "Focus on health, education and environmental protection in this village.",
            "Enhance the quality of living in rural areas and support local businesses."
        ],
        statistics:[
            {title:"Brief summary of work carried out & which year(s) the project work took place:",
                statistics :[
                   "1- Opening date: May 18, 2014.",
                   "2- Completion of work: Interior and exterior painting, electricity and plumbing, floor tile, waterproofing and exterior enclosure.",
                   "3- Participation of local association and habitants."
                ] },
            {title:"Brief summary of work carried out & which year(s) the project work took place:",
                statistics :[
                    "Current statistics:",
                    "1- Year school: 2014/2015: 28",
                    "2- Year school: 2015/2016: 15",
                    "3- Year school: 2016/2017: 19"
                ] },
            {title:"Brief summary of work carried out & which year(s) the project work took place:",
                statistics :[
                    "Improvement of learning ability.",
                    "Smooth school to primary school.",
                    "Easy access to primary school."
                ] },
        ]
    },
    {
        id:4,
        slug:"tizi-ouadou-rural-learning-center",
        title:"Tizi Ouadou Rural Learning Center",
        image:"Tizi Ouadou Rural Learning Center",
       
        intro:[
            "Tizi Ouadou is situated at 1200m .There are a set of scattering Berber villages totally inhabited by Berber communities whose main works are farming and livestock.",
            "Childreach International and Travel Link have initiated an educational centre in 2014, through a succession of volunteering groups taking part in various building stages. The centre has currently been open, benefits a great number of children.",
            "You will need a day journey to reach this spectacular village connecting Marrakech to Moulay Brahim) to get to Tizi Ouadou village, whose inhabitants will warmly receive you and let you know about their simple way of life ."
        ],
        obj_title:"The objectives of Tizi Ouadou village centre :",
        objectifs :[
            "Create learning centre in this village and provide a high quality education for children.",
            "Create sustainable opportunities in favour of rural women.",
            "Raise awareness of the ressources and heritage of this rural area.",
            "Focus on health, education and environmental protection in this village.",
            "Enhance the quality of living in rural areas and support local businesses."
        ],
        statistics:[
            {title:"Brief summary of work carried out & which year(s) the project work took place:",
                statistics :[
                    "1- Opening date: December 23, 2014.",
                    "2- Completion of work: Interior and exterior painting, electricity and plumbing, floor tile and waterproofing.",
                    "Participation of Big Built Morocco:02 Groups"
                ] },
            {title:"Brief summary of work carried out & which year(s) the project work took place:",
                statistics :[
                    "Current statistics: 73",
                    "1- Year school: 2014/2015: 28",
                    "2- Year school: 2015/2016: 21",
                    "3- Year school: 2016/2017: 24"
                ] },
            {title:"Any current statistics/ ranking information/achievements of the centre/pupils:",
                statistics :[
                    "Improvement of learning ability.",
                    "Smooth school to primary school.",
                    "Easy access to primary school."
                ] },
        ]
    },

    {
        id:5,
        slug:"mriwat-rural-learning-center",
        title:"Mriwat Rural Learning Center",
        image:"Mriwat Rural Learning Center",
        intro:[
          "Mriwat is situated at 1400m .There are a set of scattering Berber villages totally inhabited by Berber communities whose main works are farming and livestock.",
          "Childreach International and Travel Link have initiated an educational centre in March 2016, through a succession of volunteering groups taking part in various building stages. The centre has currently been open, benefits a great number of children.",
          "You will need a day journey to reach this spectacular village connecting Marrakech toTighdouine) to get to Marigha village, whose inhabitants will warmly receive you and let you know about their simple way of life .",

        ],
        obj_title:"The objectives of Mriwat village centre :",
        objectifs :[
            "Create learning centre in this village and provide a high quality education for children.",
            "Create sustainable opportunities in favour of rural women.",
            "Raise awareness of the ressources and heritage of this rural area.",
            "Focus on health, education and environmental protection in this village.",
            "Enhance the quality of living in rural areas and support local businesses."
        ],
        statistics:[
            {title:"Brief summary of work carried out & which year(s) the project work took place:",
                statistics :[
                    "1- Opening date: Mars 1, 2016.",
                    "2- Completion of work: Interior and exterior painting, electricity and plumbing, floor tile and waterproofing and exterior enclose.",
                    "3- Participation of Big Built Morocco:02 Groups"
                ] },
            {title:"Number of children directly impacted/number of indirect beneficiaries:",
                statistics :[
                   "Current statistics: 47",
                   "1- Year school: 2015/2016: 26",
                   "2- Year school: 2016/2017: 21"
                ] },
            {title:"Number of children directly impacted/number of indirect beneficiaries:",
                statistics :[
                    "Improvement of learning ability.",
                    "Smooth school to primary school.",
                    "Easy access to primary school."
                ] },
        ]
    },
    {
        id:6,
        slug:"ait-bouali-rural-learning-center",
        title:"Ait Bouali Rural Learning Center",
        image:"Ait Bouali Rural Learning Center",
        
        intro:[
            "Ait Bouali is situated at 1400m .There are a set of scattering Berber villages totally inhabited by Berber communities whose main works are farming and livestock.",
            "Childreach International and Travel Link have initiated an educational centre in March 2016, through a succession of volunteering groups taking part in various building stages. The centre has currently been open, benefits a great number of children.",
            "You will need a day journey to reach this spectacular village connecting Marrakech toTighdouine) to get to Marigha village, whose inhabitants will warmly receive you and let you know about their simple way of life "
        ],
        obj_title:"The objectives of Ait Bouali  village centre :",
       
        objectifs :[
            "Create learning centre in this village and provide a high quality education for children.",
            "Create sustainable opportunities in favour of rural women.",
            "Raise awareness of the ressources and heritage of this rural area.",
            "Focus on health, education and environmental protection in this village.",
            "Enhance the quality of living in rural areas and support local businesses."
        ],
        statistics:[
            {title:"Brief summary of work carried out & which year(s) the project work took place:",
                statistics :[
                    "1- Opening date: November 21, 2016.",
                    "2- Completion of work: Interior and exterior painting, electricity and plumbing, floor tile and waterproofing.",
                    "3- Participation of Sage Foundation volunteers & Big Built Morocco:01 Groups",
                ] },
            {title:"Brief summary of work carried out & which year(s) the project work took place:",
                statistics :[
                    "Current statistics:",
                    "1- Year school: 2016/2017: 42",
                ] },
            {title:"Brief summary of work carried out & which year(s) the project work took place:",
                statistics :[
                    "Improvement of learning ability.",
                    "Smooth school to primary school.",
                    "Easy access to primary school."
                ] },
        ]
    },
    {
        id:7,
        slug:"talatast-rural-learning-center",
        title:"Talatast Rural Learning Center",
        image:"Talatast Rural Learning Center",
        intro:[
            "Located south from Marrakech at the foot of the Atlas Mountains; Talatst is around one hour drive on a dirt road from Marrakech in direction of Aït Ourir ; Ourzazate and Larbaa Tighdouine in Zat valley. The Inhabitants are Berber people, living from terraced farming and a wide livestock. ",
            "British Moroccan Society and Travel Link have set up a charitable centre, part of it is a kindergarten. A weaving and embroidery workshops will be available in the coming weeks. There are about 27 kids, 25 secondary and high school pupils and 30 women and young girls benefiting from the multifunctional centre.",
            "We can organise a half day trip to enjoy the pretty scenery of the foothills. You will be also able to explore the village, visit the educational centre, the pottery workshops and finally share a glass of mint tea with a local family before you head back to Marrakech."
        ],
        obj_title:"The objectives of Talatast village centre :",
       
        objectifs :[
            "Create learning centre in this village and provide a high quality education for children.",
            "Create sustainable opportunities in favour of rural women.",
            "Raise awareness of the ressources and heritage of this rural area.",
            "Focus on health, education and environmental protection in this village.",
            "Enhance the quality of living in rural areas and support local businesses."
        ],
        statistics:[
            {title:"Brief summary of work carried out & which year(s) the project work took place:",
                statistics :[
                    "1- Opening date: October 07, 2012.",
                    "2- Completion of work: Interior and exterior painting, electricity and plumbing, floor tile, waterproofing.",
                    "3- Participation of local association and habitants."
                ] },
            {title:"Brief summary of work carried out & which year(s) the project work took place:",
                statistics :[
                    "Current statistics:",
                    "1- Year school: 2012/2013: 24",
                    "2- Year school: 2013/2014: 30",
                    "3- Year school: 2014/2015: 24",
                    "4- Year school: 2015/2016: 18",
                    "5- Year school: 2016/2017: 17"
                ] },
            {title:"Brief summary of work carried out & which year(s) the project work took place:",
                statistics :[
                    "Improvement of learning ability.",
                    "Smooth school to primary school.",
                    "Easy access to primary school."
                ] },
        ]
    },
    {
        id:8,
        slug:"agounsane-rural-learning-center",
        title:"Agounsane Rural Learning Center",
        image:"Agounsane Rural Learning Center",
        intro:[
            "Located south from Marrakech at the foot of the Atlas Mountains; Agounsane is around ten minutes drive on a dirt road from Ourika valley. The Inhabitants are Berber people, living from terraced farming and a wide livestock.",
            "Saga Holidays and Travel Link have set up a charitable centre, part of it is a kindergarten and the other part is a library equipped with computers. A weaving and embroidery workshops will be available in the coming weeks. There are about 30 kids, 25 secondary and high school pupils and 40 women benefiting from the multifunctional centre.",
            "We can organise a half day trip to enjoy the pretty scenery of the foothills. You will be also able to explore the village, visit the educational centre, the pottery workshops and finally share a glass of mint tea with a local family before you head back to Marrakech."
        ],
        obj_title:"The objectives of Agounsane village centre :",
       
        objectifs :[
            "Create learning centre in this village and provide a high quality education for children.",
            "Create sustainable opportunities in favour of rural women.",
            "Raise awareness of the ressources and heritage of this rural area.",
            "Focus on health, education and environmental protection in this village.",
            "Enhance the quality of living in rural areas and support local businesses."
        ],
        statistics:[
            {title:"Brief summary of work carried out & which year(s) the project work took place:",
                statistics :[
                    "1- Opening date: October 07, 2012.",
                    "2- Completion of work: Interior and exterior painting, electricity and plumbing, floor tile, waterproofing.",
                    "3- Participation of local association and habitants."
                ] },
            {title:"Brief summary of work carried out & which year(s) the project work took place:",
                statistics :[
                    "Current statistics:",
                    "1- Year school: 2012/2013: 24",
                    "2- Year school: 2013/2014: 30",
                    "3- Year school: 2014/2015: 24",
                    "4- Year school: 2015/2016: 18",
                    "5- Year school: 2016/2017: 17",
                ] },
            {title:"Brief summary of work carried out & which year(s) the project work took place:",
                statistics :[
                    "Improvement of learning ability.",
                    "Smooth school to primary school.",
                    "Easy access to primary school."
                ] },
        ]
    
    },
    {
        id:9,
        slug:"marigha-rural-learning-center",
        title:"Marigha Rural Learning Center",
        image:"Marigha Rural Learning Center",
        intro:[
            "Marigha is situated at 1200m .There are a set of scattering Berber villages totally inhabited by Berber communities whose main works are farming and livestock.",
            "Childreach International and Travel Link have initiated an educational centre in 2014, through a succession of volunteering groups taking part in various building stages. The centre has currently been open, benefits a great number of children.",
            "You will need a day journey to reach this spectacular village connecting Marrakech to Taroudant ) to get to Marigha village, whose inhabitants will warmly receive you and let you know about their simple way of life "
        ],
        obj_title:"The objectives of Agounsane village centre :",
       
        objectifs :[
            "Create learning centre in this village and provide a high quality education for children.",
            "Create sustainable opportunities in favour of rural women.",
            "Raise awareness of the ressources and heritage of this rural area.",
            "Focus on health, education and environmental protection in this village.",
            "Enhance the quality of living in rural areas and support local businesses."
        ],
        statistics:[
            {title:"Brief summary of work carried out & which year(s) the project work took place:",
                statistics :[
                    "1- Opening date: November 30, 2014.",
                    "2- Completion of work: Interior and exterior painting, electricity and plumbing, floor tile and waterproofing. Participation of Big Built Morocco:02 Groups"
                ] },
            {title:"Brief summary of work carried out & which year(s) the project work took place:",
                statistics :[
                    "1- Current statistics: 91",
                    "2- Year school: 2014/2015: 46",
                    "3- Year school: 2015/2016: 24",
                    "4- Year school: 2016/2017: 21",
                ] },
            {title:"Brief summary of work carried out & which year(s) the project work took place:",
                statistics :[
                    "Improvement of learning ability.",
                    "Smooth school to primary school.",
                    "Easy access to primary school."
                ] },
        ]
    }
]

function ProjectDetails() {

    const {slug} =  useParams()

    const [project, setProject] = useState({})

    useEffect(() => {
        let p = projects_data.find(item => item.slug === slug)
        setProject(p)

    }, [slug])


    

    return (
        <div className='min-h-screen'>
            <section className='pt-20 max-w-screen-lg mx-auto '>
                <div className="grid grid-cols-2 gap-2 p-4">
                    <div className='h-32 '>
                        <img src={`/images/projects/${slug}/1.png`} className="w-full h-full object-cover " alt="" srcset="" /> 
                    </div>
                    
                    <div className='h-32 '>
                        <img src={`/images/projects/${slug}/2.png`} className="w-full h-full object-cover " alt="" srcset="" /> 
                    </div>

                    <div className='h-32 flex items-center justify-center'>
                        <h1 className='md:text-4xl text-center font-bold'> {project?.title}</h1>
                    </div>
                    <div className='h-32 '>
                        <img src={`/images/projects/${slug}/3.png`} className="w-full h-full object-cover " alt="" srcset="" /> 
                    </div>
                    <div className='h-32 '>
                        <img src={`/images/projects/${slug}/4.png`} className="w-full h-full object-cover " alt="" srcset="" /> 
                    </div>
                    <div className='grid grid-cols-2 gap-2 '>
                        <div className='h-32'>
                            <img src={`/images/projects/${slug}/5.png`} className="w-full h-full object-cover " alt="" srcset="" /> 
                        </div>
                        <div className=' h-32 '>
                            <img src={`/images/projects/${slug}/6.png`} className="w-full h-full object-cover " alt="" srcset="" /> 
                        </div>
                    </div>


                </div>
            </section>

            <section className='pt-10  text-center max-w-screen-lg  mx-auto '>
                <div className='bg-primary rounded-md p-10 m-4 '>
                    {project.intro?.map(intr => <p className='pt-3' key={intr}>{intr}</p>)}
                   
                </div>
            
            </section>

            <section className='pt-10 max-w-screen-lg mx-auto p-4'>
                <div className='md:flex items-center justify-center md:flex-row flex-col-1 gap-8 '>
                    <div className='md:w-1/2 p-8'>
                        <img src={`/images/projects/${slug}/${slug}.png`} alt="" />
                    </div>
                    <div className='md:w-1/2 '>
                        <h1 className='text-3xl font-bold'>{project.obj_title}</h1>
                        <div className='pt-4'>
                            <ul className='list-image-check pl-8 md:pl-4'>
                                {project.objectifs?.map(obj => <li key={obj} className='p-2'>
                                        {obj}
                                    </li> )}
                            </ul>

                        </div>
                    </div>
                

                </div>
            </section>


            <section className='py-10 max-w-screen-lg mx-auto p-4'>
                <h1 className='text-3xl font-bold'>Statistics</h1>
                {project.statistics?.map( stc =>
                    <div className='grid grid-cols-1 md:grid-cols-3 my-3 gap-3'>
                        <div className='bg-[#F2464C] bg-opacity-50 p-3'>
                            {stc.title}
                        </div>
                        <div className='md:col-span-2 p-3 bg-[#F2464C] bg-opacity-50'>
                            <ul>
                                {stc.statistics?.map(it => <li>
                                    {it}
                                </li>)}
                            </ul>
                           
                        </div>
                    </div>

                )}
            </section>
            

        </div>
    )
}

export default ProjectDetails