import React from "react";

function Programs() {
  return (
    <div>
      <div
        className="md:h-[70vh] h-screen  bg-no-repeat flex items-center justify-center bg-cover w-full bg-top"
        style={{ backgroundImage: "url(/images/programs/header.png)" }}
      >
        <div className="max-w-screen-xl mx-auto">
          <h1 className="text-7xl text-center text-white  font-bold">
            Our programs
          </h1>
        </div>
      </div>

      <div className="flex pb-10 items-center justify-end pr-4">
        <div className=" bg-primary h-20 flex items-center px-4 rounded-xl shadow-sm gap-4 -mt-10">
          <div>
            <span className="text-4xl font-bold">400+</span> Beneficiaries
          </div>
          <div>
            <span className="text-4xl font-bold">50+</span> Projects
          </div>
        </div>
      </div>

      <div className="py-10 max-w-screen-lg mx-auto p-4">
        <div className="flex flex-col md:flex-row flex-col-1 gap-8 ">
          <div className="md:w-1/3 ">
            <img src="/images/programs/programs.png" alt="" />
          </div>
          <div className="md:w-2/3 ">
            <h1 className="text-3xl font-bold">
              We crave to create a better tomorrow for children
            </h1>

            <div className="pt-4">
              <p>
                The foundation currently creates and supports programs that
                address the education, safety, and well-being of local people in
                the High Atlas Mountains, children-focused initiatives and
                community organizations that help people in need
              </p>
              <p className="pt-3">
                Travel Link Foundation is made up of members from different
                fields: academics, university professors, tourism professionals,
                business leaders; each of them brings their expertise and
                experience to serve local communities and create a better future
                for th
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="py-10 max-w-screen-lg mx-auto p-4">
        <h1 className="text-3xl font-bold py-5">Our Programs</h1>
        <div className="grid md:grid-cols-3 items-center ">
          <div className="p-8 md:block hidden">
            <img
              src="/images/programs/our_programs.png"
              className="rounded-md shadow-md"
              alt=""
              srcset=""
            />
          </div>
          <div className="md:col-span-2 px-8">
            <h1 className="text-2xl font-semibold py-3">
              Women's Education and Training Center
            </h1>
            <ul className="list-decimal pl-6">
              <li className="py-2 px-2 ">
                <span className="font-semibold">
                  Equipment of the Talatast Center. Rural Tighdouine:
                </span>

                <tr className="border bg-primary">
                  <td className="border p-2 border-black">
                    3 sewing machines delivered.
                  </td>
                  <td className="border p-2 border-black">
                    Beneficiaries: 30 girls.
                  </td>
                </tr>
              </li>
              <li className="py-2 px-2 ">
                <span className=" font-semibold">
                  Center of Agounsane. Rural commune Ourika.{" "}
                </span>

                <tr className="border bg-primary">
                  <td className="border p-2 border-black">
                    Opening: 27/02/2015
                  </td>
                  <td className="border p-2 border-black">
                    Beneficiaries: 54 Women and girls.
                  </td>
                </tr>
              </li>
              <li className="py-2 px-2 ">
                <span className=" font-semibold">
                  Marigha Center. Rural district Ouirgane{" "}
                </span>
                <tr className="border bg-primary">
                  <td className="border p-2 border-black">
                    Opening: 19 /09/2016
                  </td>
                  <td className="border p-2 border-black">
                    Beneficiaries: 46 Women and girls.
                  </td>
                </tr>
              </li>
              <li className="py-2 px-2 ">
                <span className=" font-semibold">
                  Ouakrim Benlahcen Center in Tamghatzdane, Touama
                </span>
                <tr className="border bg-primary">
                  <td className="border p-2 border-black">
                    Opening: 23/10/2017
                  </td>
                  <td className="border p-2 border-black">
                    Beneficiaries: 75 Women and girls.
                  </td>
                </tr>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="py-10 max-w-screen-lg mx-auto p-4">
        <div className="grid grid-cols-1 md:grid-cols-3 items-center ">
          <div className="md:col-span-2 px-8">
            <h1 className="text-2xl font-semibold py-3">
              Environment and Protection of Local Heritage
            </h1>
            <ul className="list-image pl-6" id="responsable">
              <li className="py-2 px-2 ">
                Construction of a traditional bridge on the Oued Ouraken.
                05/18/2014.
              </li>
              <li className="py-2 px-2 ">
                Construction of protective walls against landslide at Aït Hkim
                and Mriwat centers.
              </li>
              <li className="py-2 px-2 ">
                Sensitization and assistance of the potters of Talatast and
                Agounsane for the creation of their cooperative.
              </li>
              <li className="py-2 px-2 ">
                Planting olive trees in the courtyards of Ouarialt and Taourirt
                centers by voluntary tourists.
              </li>
            </ul>
          </div>
          <div className="p-8">
            <img
              src="/images/programs/envrin.png"
              className="rounded-md shadow-md"
              alt=""
              srcset=""
            />
          </div>
        </div>
      </div>

      <div className="py-10 max-w-screen-lg mx-auto p-4">
        <div className="grid grid-cols-1 md:grid-cols-3 items-center ">
          <div className="p-8">
            <img
              src="/images/programs/tourism.png"
              className="rounded-md shadow-md"
              alt=""
              srcset=""
            />
          </div>
          <div className="md:col-span-2 px-8">
            <h1 className="text-2xl font-semibold py-3">
              Responsible And Solidary Tourism
            </h1>
            <ul className="list-image pl-6">
              <li className="py-2 px-2 ">
                Working for a responsible tourism, respectful of people,
                cultures and environment.
              </li>
              <li className="py-2 px-2 ">
                Participating in the achievement of small projects in education
                and training based on local partners.
              </li>
              <li className="py-2 px-2 ">
                Raising public awareness and local providers of the importance
                of rural tourism by involving them in a process of reaching for
                quality and upgrading products.
              </li>
              <li className="py-2 px-2 ">
                Promoting a responsible tourism that respects and preserves
                long-term natural, cultural and social resources, and that
                contributes positively to a sustainable and equitable
                development.
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/*  */}
      <div className="py-10 max-w-screen-lg mx-auto p-4">
        <div className="grid grid-cols-1 md:grid-cols-3 items-center ">
          <div className="md:col-span-2 px-8">
            <h1 className="text-3xl font-semibold py-3">Medical caravan</h1>
            <ul className="list-image pl-6">
              <li className="py-2 px-2">
                Offering medical consultations and treatments to a large patient
                population, numbering over 600 individuals.
              </li>
              <li className="py-2 px-2 ">
                Mobilizing a team of over 30 medical professionals, including
                doctors and assistants, to provide care.
              </li>
              <li className="py-2 px-2 ">
                Ensuring that all necessary medicines and medical equipment were
                readily available to provide comprehensive care to patients.
              </li>
            </ul>
            <div>
              <a
                href="/programs/medical-caravan/"
                className="text-[#1439FB] underline cursor-pointer pl-6"
              >
                Read more
              </a>
            </div>
          </div>
          <div className="p-8">
            <img
              src="/images/programs/medicalprog.png"
              className="rounded-md shadow-md"
              alt=""
              srcset=""
            />
          </div>
        </div>
      </div>
      {/*  */}
      <div className="py-10 max-w-screen-lg mx-auto p-4">
        <div className="grid grid-cols-1 md:grid-cols-3 items-center ">
          <div className="p-8">
            <img
              src="/images/programs/earthquake.png"
              className="rounded-md shadow-md"
              alt=""
              srcset=""
            />
          </div>
          <div className="md:col-span-2 px-8">
            <h1 className="text-3xl font-semibold py-3">
              Earthquake Relief Program
            </h1>
            <ul className="list-image pl-6">
              <li className="py-2 px-2 ">Visited more than 300 villages</li>
              <li className="py-2 px-2 ">
                Provided help to over 1,000 families
              </li>
              <li className="py-2 px-2 ">
                Constructed more than 50 shelters/schools
              </li>
              <li className="py-2 px-2 ">Distributed over 5,000 baskets</li>
            </ul>
            <div>
              <a
                href="/programs/earthquake/"
                className="text-[#1439FB] underline cursor-pointer pl-6"
              >
                Read more
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Programs;
