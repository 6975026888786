import Gallery from "components/Gallery";
import Members from "components/Members";
import Projects from "components/Projects";
import React from "react";

function Home() {
  return (
    <>
      <div className="bg-primary ">
        <div
          className="md:h-[88vh] h-screen bg-no-repeat flex items-center bg-cover w-full bg-top "
          style={{ backgroundImage: "url(./images/home/header.png)" }}
        >
          <div className="max-w-screen-xl mx-auto">
            <h1 className="text-5xl text-center max-w-4xl px-4 lg:text-7xl top-1/2 text-white  font-bold">
              Leaving a meaningful impact
            </h1>
          </div>
        </div>

        <div className="max-w-3xl flex items-center gap-4 mx-auto p-8 -mt-32 bg-white rounded-xl">
          <div className="md:w-2/3 w-full text-sm">
            <p className="opacity-75">Who are we ?</p>
            <h1 className="text-5xl">About us</h1>
            <p className="opacity-70 py-1">
              Travel link foundation is a non-profit organization based in
              Morocco created in 2011.
            </p>
            <p className="opacity-70 py-1">
              Our mission is to raise awareness and find sustainable solutions
              over the most critical issues that directly affect the life in the
              Atlas Mountains villages.
            </p>
            <div className="pt-4">
              <a href="/about-us">
                <button className="px-4  bg-black text-white py-2 rounded-full">
                  Read more
                </button>
              </a>
            </div>
          </div>
          <div className="md:w-1/3 md:block hidden">
            <img src="/images/home/about_us.png" alt="" />
          </div>
        </div>

        <div className="pt-10 max-w-screen-lg mx-auto p-4">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 ">
            <div className="bg-white p-5 rounded-md flex items-center">
              <span className="text-5xl font-bold pr-4">20+</span>{" "}
              <span className="opacity-80">Projects</span>
            </div>
            <div className="bg-white p-5 rounded-md flex items-center">
              <span className="text-5xl font-bold pr-4">400+</span>{" "}
              <span className="opacity-80">Beneficiaries</span>
            </div>
            <div className="bg-white p-5 rounded-md flex items-center">
              <span className="text-5xl font-bold pr-4">5+</span>{" "}
              <span className="opacity-80">Partners</span>
            </div>
          </div>
        </div>

        <div className="pt-10 max-w-screen-lg mx-auto p-4">
          <div className="flex md:flex-row flex-col-1 gap-8 ">
            <div className="md:w-1/3 md:block hidden">
              <img src="/images/home/objectifs.png" alt="" />
            </div>
            <div className="md:w-2/3 block  ">
              <h1 className="text-3xl font-bold">Our objectives</h1>

              <ul className="list-disc p-6">
                <li className="py-2">
                  Ensure the right to education for children in the High Atlas
                  Mountains.
                </li>
                <li className="py-2">
                  Women empowerment by creating adequate training centers.
                </li>
                <li className="py-2">Promoting sustainable tourism</li>
                <li className="py-2">
                  Collaborate with tourism organizations to implement
                  sustainable development projects.
                </li>
                <li className="py-2">
                  Encourage local actors to preserve and take care of their
                  local heritage.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-10 max-w-screen-lg mx-auto">
        <div className="md:flex p-4">
          <div className="md:w-2/3">
            <h1 className="text-3xl pb-3 font-bold py-2">Our approch</h1>
            <p className="pt-3">
              Travel Link Foundation’s approach is to help local people take
              sustainable ownership of their present and future, by creating the
              right opportunities for the education and development of children,
              women and men.
            </p>
            <p className="pt-3">
              Local people are fully engaged in the process from start to
              finish, we ensure that everyone is part of the long-term change
              covering all the necessary elements (teacher’s training and
              support, influencing public policy, access to new technologies,
              support for parents, etc.)
            </p>
          </div>
          <div className="md:w-1/3">
            <div className="grid grid-cols-2">
              <img src="images/home/im1.png" alt="" />
              <img src="images/home/im2.png" alt="" />
            </div>
            <div className="grid grid-cols-2">
              <img src="images/home/im3.png" alt="" />
              <img src="images/home/im4.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <div className="pt-10 max-w-screen-lg mx-auto p-4">
        <h1 className="text-4xl py-6 font-bold">Our Programs</h1>
        <div className="pt-4">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {/* Earthquak Relief Program */}
            <a href={`/programs/earthquake/`}>
              <div className="bg-white rounded-xl border shadow-sm h-full">
                <img
                  src={"/images/programs/earthprogitem.png"}
                  className="aspect-video w-full object-cover rounded-t-xl"
                  alt=""
                />
                <div className="p-4">
                  <h1 className="text-xl font-bold ">
                    Earthquak Relief Program
                  </h1>
                </div>
              </div>
            </a>
            {/* Medical Caravan */}
            <a href={`/programs/medical-caravan/`}>
              <div className="bg-white rounded-xl border shadow-sm h-full ">
                <img
                  src={"/images/programs/medicalprogitem.png"}
                  className="aspect-video w-full object-cover rounded-t-xl"
                  alt=""
                />

                <div className="p-4">
                  <h1 className="text-xl font-bold ">Medical Caravan</h1>
                </div>
              </div>
            </a>
            {/* Responsible And Solidary Tourism */}
            <a href={`/programs/#responsable`}>
              <div className="bg-white rounded-xl border shadow-sm h-full">
                <img
                  src={"/images/programs/responsibleprogitem.png"}
                  className="aspect-video w-full object-cover rounded-t-xl"
                  alt=""
                />
                <div className="p-4">
                  <h1 className="text-xl font-bold ">
                    Responsible And Solidary Tourism
                  </h1>
                </div>
              </div>
            </a>
          </div>

          <div className="py-4 flex items-center justify-center">
            <a href="/programs">
              <button className="py-3 px-8 border hover:bg-gray-50 rounded-full">
                See More{" "}
              </button>
            </a>
          </div>
        </div>
      </div>

      {/*  */}

      <Projects />

      <Members />

      <Gallery />
    </>
  );
}

export default Home;
