import React from "react";

function EarthquakeProgram() {
  return (
    <div>
      {/*  */}
      <div className=" mt-16 container mx-auto">
        <img
          src="/images/programs/headerearthquakeprogram.png"
          className="w-full "
        />
      </div>
      {/*  */}
      <div className="md:my-16 my-10 mx-5">
        <p className=" w-full bg-[#A1BFED] md:px-8 px-4 md:py-10 py-5 text-center md:text-2xl text-sm shadow-md sha-[#000000]">
          In the wake of the devastating earthquake that struck near the town of
          Oukaïmedene in western Morocco on September 8, 2023, The Travel Link
          Foundation is actively extending its compassionate support to over 300
          villages nestled in the Atlas Mountains. Recognizing the urgent needs
          of the affected communities, our organization is committed to making a
          meaningful impact by providing crucial necessities to those struggling
          with the consequences of this natural disaster.
        </p>
      </div>
      {/*  */}
      <section className="md:mt-10 mt-4 container mx-auto p-4">
        <div className="md:flex items-center justify-center md:flex-row flex-col-1 gap-8 ">
          <div className="md:w-1/2 w-full md:p-8 p-4">
            <img
              className="mx-auto"
              src={`/images/programs/earthprog.png`}
              alt=""
            />
          </div>
          <div className="md:w-1/2 w-full ">
            <h1 className="md:text-4xl text-xl font-bold">
              The objectives of the Earthquake Relief Program
            </h1>
            <div className="my-4">
              <ul className="list-image-check pl-8 md:pl-4">
                <li className="p-2  md:text-base text-sm">
                  Provide urgent and essential aid to over 300 villages in the
                  Atlas Mountains, including the distribution of food, bedding,
                  blankets, and shelters, to address the immediate needs of the
                  affected communities.
                </li>
                {/*  */}
                <li className="p-2 md:text-base text-sm">
                  Offer comprehensive support to enhance the physical and
                  emotional well-being of individuals and families impacted by
                  the earthquake.
                </li>
                {/*  */}
                <li className="p-2 md:text-base text-sm">
                  Contribute to the long-term recovery process by initiating
                  projects focused on rebuilding homes,schools and community
                  spaces to restore normalcy.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/* static */}
      <section className="py-10 container mx-auto p-4">
        <h1 className="text-3xl font-bold">Statistics</h1>

        <div className="grid grid-cols-1 md:grid-cols-3 my-3 gap-3">
          <div className="bg-[#F2464C] bg-opacity-50 p-3">Villages visited</div>
          <div className="md:col-span-2 p-3 bg-[#F2464C] bg-opacity-50">
            <ul>
              <li>Visited more than 300 villages</li>
            </ul>
          </div>
        </div>
        {/*  */}
        <div className="grid grid-cols-1 md:grid-cols-3 my-3 gap-3">
          <div className="bg-[#F2464C] bg-opacity-50 p-3">Families helped</div>
          <div className="md:col-span-2 p-3 bg-[#F2464C] bg-opacity-50">
            <ul>
              <li>Provided help to over 1,000 families</li>
            </ul>
          </div>
        </div>
        {/*  */}
        <div className="grid grid-cols-1 md:grid-cols-3 my-3 gap-3">
          <div className="bg-[#F2464C] bg-opacity-50 p-3">
            Shelters/schools built
          </div>
          <div className="md:col-span-2 p-3 bg-[#F2464C] bg-opacity-50">
            <ul>
              <li>Constructed more than 50 shelters/schools</li>
            </ul>
          </div>
        </div>
        {/*  */}
        <div className="grid grid-cols-1 md:grid-cols-3 my-3 gap-3">
          <div className="bg-[#F2464C] bg-opacity-50 p-3">
            Baskets distributed
          </div>
          <div className="md:col-span-2 p-3 bg-[#F2464C] bg-opacity-50">
            <ul>
              <li>Distributed over 5,000 baskets</li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
}

export default EarthquakeProgram;
