import React from 'react'

const data = [
    {slug:"ouarialt-rural-learning-center",title:"Ouarialt Rural Learning Center",image:"Ouarialt Rural Learning Center"},
    {slug:"tighratine-rural-learning-center",title:"Tighratine Rural Learning Center",image:"Tighratine Rural Learning Center"},
    {slug:"taourirt-rural-learning-center",title:"Taourirt Rural Learning Center",image:"Taourirt Rural Learning Center"},
    {slug:"tizi-ouadou-rural-learning-center",title:"Tizi Ouadou Rural Learning Center",image:"Tizi Ouadou Rural Learning Center"},
    {slug:"mriwat-rural-learning-center",title:"Mriwat Rural Learning Center",image:"Mriwat Rural Learning Center"},
    {slug:"ait-bouali-rural-learning-center",title:"Ait Bouali Rural Learning Center",image:"Ait Bouali Rural Learning Center"},
    {slug:"talatast-rural-learning-center",title:"Talatast Rural Learning Center",image:"Talatast Rural Learning Center"},
    {slug:"agounsane-rural-learning-center",title:"Agounsane Rural Learning Center",image:"Agounsane Rural Learning Center"},
    {slug:"marigha-rural-learning-center",title:"Marigha Rural Learning Center",image:"Marigha Rural Learning Center"}
]

const Item = ({item}) => {
    return (
        <a href={`/projects/${item.slug}`}>
            <div className='bg-white rounded-xl border shadow-sm '>
                <img src={`images/projects/${item.title}.png`} className='aspect-video w-full object-cover rounded-t-xl' alt="" />
                
                <div className='p-4'>
                    <h1 className='text-xl text-center font-bold '>
                    {item.title}
                    </h1>
                
                </div>
                
            </div>
        </a>
    )
}

function Projects() {
  return (
    <div>
        <div className='md:h-[70vh] h-screen  bg-no-repeat flex items-center justify-center bg-cover w-full bg-center' style={{backgroundImage:'url(./images/projects/header.png)'}}>
            <div className='max-w-screen-xl mx-auto'>
                <h1 className='text-7xl  text-white  font-bold'>
                    Our Projects
                </h1>
            </div>
        </div>

        <div className='pt-10 max-w-screen-lg mx-auto p-4'>
            <div className='md:flex items-center justify-center md:flex-row flex-col-1 gap-8 '>
                <div className='md:w-1/2 p-8'>
                    <img src="images/projects/our_goals.png" alt="" />
                </div>
                <div className='md:w-1/2 '>
                    <h1 className='text-3xl font-bold'>Our goals</h1>

                    <div className='pt-4'>
                       <ul className='list-image-check pl-8 md:pl-4'>
                            <li className='p-3'>
                                Suporting children’s education
                            </li>
                            <li className='p-3'>
                                Empowering woman
                            </li>
                            <li className='p-3'>
                                Promoting sustainable tourism
                            </li>
                       </ul>

                    </div>
                </div>
            

            </div>
        </div>

        <div className='pt-10  max-w-screen-lg mx-auto p-4'>
            <div className='grid grid-cols-1 md:grid-cols-3 gap-8'>
                {data.map(item => <Item item={item} />)}
            </div>
        </div>

    </div>
  )
}

export default Projects