import React from 'react'


function Footer() {
    return (
        <div className='bg-black p-8 text-white'>
            <div  className="md:flex md:flex-row flex-col md:justify-center  max-w-screen-lg mx-auto gap-4  ">
                <div className='md:w-1/3'>
                    <div className='pb-4'>
                        Phone : <br />
                        <div className='pt-1'>
                            <a href='tel:+212661148451'>
                                +212 661 148 451
                            </a>
                        </div>
                       
                    </div>
                    <div className='pb-4'>
                        Email : <br />
                        <p className='pt-1'>
                            <a href="mailto:contact@travellinkfoundation.org">
                                contact@travellinkfoundation.org
                            </a>
                               
                        </p>
                    
                    </div>
                </div>
                
                <div className='md:w-1/3 md:pl-4'>
                    <ul>
                        <li>
                            <a href="/about-us">About us</a>
                        </li>
                        <li>
                            <a href="/programs">Our programs</a>
                            
                        </li>
                        <li>
                            <a href="/members">Our members</a>
                        </li>
                        <li>
                            <a href="/gallery">Gallery</a>
                            
                        </li>
                    </ul>
                </div>

                <div className='flex pt-6 md:pt-0 gap-4'>
                    <div>
                        <a href="/contact-us">Contact Us</a>
                        
                    </div>
                    <div>
                        |
                    </div>
                    <div>
                        <a href="#instagram"><img src="/icons/instagram.png" alt="" srcset="" /></a>
                        
                    </div>
                </div>
                
            </div>

            
        </div>
    )
}

export default Footer