import React, { useRef, useState } from 'react'


function Gallery() {

    const [imageSource, setImageSource] = useState(0)

    const zoomImage = (index) => (e)=> {
        // console.log(parseInt(e.target.src.slice(-5).split('.')[0]))
        // console.log(e.target.src.slice(-6).split('.')[0])
        setImageSource(index)
     
    }



    return (
        <div className=''>
            <div className='md:h-[70vh] h-screen bg-no-repeat flex items-center justify-center bg-cover min-w-full bg-center' style={{backgroundImage:'url(./images/gallery/header.png)'}}>
                <div className='max-w-screen-xl mx-auto'>
                    <h1 className='text-7xl  text-white  font-bold'>
                        Gallery
                    </h1>
                </div>
            </div>

            <div className='py-20 max-w-screen-lg mx-auto p-4'>
                <h1 className='text-3xl font-bold'>Gallery</h1>
                <div className='pt-10'>
                    <div className='grid md:grid-cols-4 grid-cols- gap-3 py-2'>
                        {/* {[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15].map(item => <div key={item} className={item % arr[(Math.random() * arr.length) | 0]  ===0 ? `col-span-${arr[(Math.random() * arr.length) | 0]}`:""}> <img className='md:h-full h-32 w-full hover:cursor-zoom-in object-cover block rounded-xl shadow-sm' src={`/images/gallery/${item}.png`} /></div>)} */}
                        {/* <img className='md:h-full h-32 w-full hover:cursor-zoom-in object-cover block rounded-xl shadow-sm' src={`/images/gallery/1.png`} /> */}
                        <div className='md:col-span-2 '>
                            <img onClick={zoomImage(1)} className='md:min-w-full cursor-zoom-in md:h-full object-cover block rounded-xl shadow-sm' src={`/images/gallery/1.png`} alt=""/>
                    
                        </div>  
                        <div className='grid  grid-cols-2 md:col-span-2 gap-3 '> 
                            <img onClick={zoomImage(2)} className='md:h-full h-32 w-full hover:cursor-zoom-in object-cover block rounded-xl shadow-sm' src={`/images/gallery/2.png`} alt=""/>              
                            <img onClick={zoomImage(3)} className='md:h-full h-32 w-full hover:cursor-zoom-in object-cover block rounded-xl shadow-sm' src={`/images/gallery/3.png`} alt=""/>    
                            <img onClick={zoomImage(4)} className='md:h-full h-32 w-full hover:cursor-zoom-in object-cover block rounded-xl shadow-sm' src={`/images/gallery/4.png`} alt=""/>
                            <img onClick={zoomImage(5)} className='md:h-full h-32 w-full hover:cursor-zoom-in object-cover block rounded-xl shadow-sm' src={`/images/gallery/5.png`} alt=""/>
                        </div>  
                        
                            
                    </div>
                    <div className='grid grid-cols-3  gap-4 '>
                            <img onClick={zoomImage(6)} className='md:h-full h-32 w-full hover:cursor-zoom-in object-cover block rounded-xl shadow-sm' src={`/images/gallery/6.png`} alt=""/>   
                            <img onClick={zoomImage(7)} className='md:h-full h-32 w-full hover:cursor-zoom-in object-cover block rounded-xl shadow-sm' src={`/images/gallery/7.png`} alt=""/>
                            <img onClick={zoomImage(8)} className='md:h-full h-32 w-full hover:cursor-zoom-in object-cover block rounded-xl shadow-sm' src={`/images/gallery/8.png`} alt=""/>    
                    </div>  
                    <div className='grid grid-cols-2 md:grid-cols-4 gap-3 py-2'>
                        <div className='grid grid-cols-2 col-span-2 gap-3 '>
                            <img onClick={zoomImage(9)} className='md:h-full h-32 w-full hover:cursor-zoom-in object-cover block rounded-xl shadow-sm' src={`/images/gallery/9.png`} alt=""/>
                            <img onClick={zoomImage(10)} className='md:h-full h-32 w-full hover:cursor-zoom-in object-cover block rounded-xl shadow-sm' src={`/images/gallery/10.png`} alt=""/>
                            <img onClick={zoomImage(11)} className='md:h-full h-32 w-full hover:cursor-zoom-in object-cover block rounded-xl shadow-sm' src={`/images/gallery/11.png`} alt=""/>
                            <img onClick={zoomImage(12)} className='md:h-full h-32 w-full hover:cursor-zoom-in object-cover block rounded-xl shadow-sm' src={`/images/gallery/12.png`} alt=""/>
                        </div>  
                        <div className='col-span-2 '>
                            <img onClick={zoomImage(13)} className='md:h-full  w-full hover:cursor-zoom-in object-cover block rounded-xl shadow-sm' src={`/images/gallery/13.png`} alt=""/>
                        </div> 
                        
                            
                    </div>
                    <div className='grid grid-cols-3  gap-4 py-2 '>
                        <img onClick={zoomImage(14)} className='md:h-full h-32 w-full hover:cursor-zoom-in object-cover block rounded-xl shadow-sm' src={`/images/gallery/14.png`} alt=""/>
                        <img onClick={zoomImage(15)} className='md:h-full h-32 w-full hover:cursor-zoom-in object-cover block rounded-xl shadow-sm' src={`/images/gallery/15.png`} alt=""/>  
                        <img onClick={zoomImage(16)} className='md:h-full h-32 w-full hover:cursor-zoom-in object-cover block rounded-xl shadow-sm' src={`/images/gallery/16.png`} alt=""/>
                        
                    </div> 
                
                </div>
            </div>
            {imageSource !== 0 && imageSource !== 17 && 
                <>
                    <div onClick={()=> setImageSource(null)} class="fixed inset-0 bg-white backdrop-blur-sm bg-opacity-75 transition-opacity"></div>
                     <div class="fixed inset-0 z-50 overflow-y-auto">
                        <div class="flex  min-h-full max-w-screen-md mx-auto items-center justify-center p-4 text-center  sm:p-0">
                            
                            <div class="relative transform overflow-hidden rounded-lg text-left shadow-xl transition-all sm:my-8 sm:min-w-full sm:max-w-lg">
                               
                                <div className=' '>
                                    <button className='text-black float-right ' onClick={() => setImageSource(0)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                    </button>
                                    <button onClick={()=> setImageSource(imageSource => imageSource +1)} className='absolute  bg-white  hover:bg-opacity-90 px-4 py-3 right-0 top-1/2'>
                                        Next  
                                    </button>
                                    <button onClick={()=> setImageSource(imageSource => imageSource -1)} className='absolute  bg-white  hover:bg-opacity-90 px-4 py-3 left-0 top-1/2'>
                                        Prev
                                    </button>
                                    {/* {imageSource} */}
                                    <img src={`/images/gallery/${imageSource}.png`} className="rounded-md min-w-full" alt="" />
                                 
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </>
             }
        </div>
    )
}

export default Gallery