import React from 'react'
import uuid from 'react-uuid'






                 
const data = [
    {slug:"talatast-rural-learning-center",image:"images/home/projects/talataste.png",title:"Talataste",subtitle:"Berber Village In Zat Valley"},
    {slug:"agounsane-rural-learning-center",image:"images/home/projects/agounsane.png",title:"Agounsane",subtitle:"Berber Village In Ourika Valley"},
    {slug:"ouarialt-rural-learning-center",image:"images/home/projects/ouarialt.png",title:"Ouarialt",subtitle:"Berber Village In Kik Plateau"},
    {slug:"tighratine-rural-learning-center",image:"images/home/projects/tighratine.png",title:"Tighratine",subtitle:"Berber Village In Ait Hkim Valley"},
    {slug:"taourirt-rural-learning-center",image:"images/home/projects/taourirt.png",title:"Taourirt",subtitle:"Berber Village In Ouraken Valley"},
    {slug:"marigha-rural-learning-center",image:"images/home/projects/marigha.png",title:"Marigha",subtitle:"Berber Village In Ouirgane Valley"},
]

const Item = ({item}) => {
    return (
        <a href={`/projects/${item.slug}`}>
            <div className='bg-white rounded-xl border shadow-sm '>
                <img src={item.image} className='aspect-video w-full object-cover rounded-t-xl' alt="" />
                
                <div className='p-4'>
                    <h1 className='text-xl font-bold '>
                    {item.title}
                    </h1>
                    <p className='opacity-75'>
                        {item.subtitle}
                    </p>
                </div>
                
            </div>
        </a>
    )
}

function Projects() {

  return (
    <div className='pt-10 max-w-screen-lg mx-auto p-4'>
        <h1 className='text-4xl py-6 font-bold'>Our projects</h1>
        <div className='pt-4'>
            <div className='grid grid-cols-1 md:grid-cols-3 gap-8'>
               
               {data.map(pr => <Item key={uuid()} item={pr} />)}
                
                
            </div>

            <div className='py-4 flex items-center justify-center'>
                <a href="/projects">
                    <button className='py-3 px-8 border hover:bg-gray-50 rounded-full'>See More </button>
                </a>
            </div>
        </div>
    </div>
  )
}

export default Projects