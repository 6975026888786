import React from "react";

function Members() {
  return (
    <div>
      <div
        className="md:h-[70vh] h-screen  bg-no-repeat flex items-center justify-center bg-cover w-full bg-top"
        style={{ backgroundImage: "url(./images/members/header.png)" }}
      >
        <div className="max-w-screen-xl mx-auto">
          <h1 className="text-4xl md:text-7xl  text-white  font-bold">
            Our Members
          </h1>
        </div>
      </div>

      <div className="pt-10 max-w-screen-lg mx-auto p-4">
        <div className="md:flex items-center justify-center md:flex-row flex-col-1 gap-8 ">
          <div className="md:w-1/2 p-8">
            <img src="images/members/members.png" alt="" />
          </div>
          <div className="md:w-1/2 ">
            <h1 className="text-3xl font-bold">Members Presentation</h1>

            <div className="pt-4">
              <p>
                Travel link foundation is managed by an executive board of 13
                elected members, representing different sectors: academics,
                university professors, tourism professionals, entrepreneurs,
                executives .....
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="pb-10 max-w-screen-lg mx-auto p-4">
        <h1 className="text-3xl font-bold">Members </h1>
        <div className="pt-4">
          <img src="images/members/member.png" alt="" />
        </div>
      </div>
    </div>
  );
}

export default Members;
