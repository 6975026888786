import React from "react";

function MedicalCaravanProgram() {
  return (
    <div>
      {/*  */}
      <div className=" mt-16 container mx-auto">
        <img src="/images/programs/headerMedical.png" className="w-full " />
      </div>
      {/*  */}
      <div className="md:my-16 my-10 mx-5">
        <div className=" w-full bg-[#A1BFED] md:px-8 px-4 md:py-10 py-5 text-center md:text-2xl text-sm shadow-md sha-[#000000]">
          <p className=" w-full md:my-5 my-3 ">
            Travel Link Foundation recently organized a Medical Caravan to serve
            a village in Agafay. A team of highly skilled doctors, each with
            their own specialties, including dentistry, pediatrics,
            ophthalmology, neurology, ENT and general medicine, were on the
            ground to provide medical care to the village inhabitants
          </p>
          <p className=" w-full md:my-5 my-3 ">
            Upon completing their medical consultations, patients were directed
            to the pharmacy to collect their prescribed medications.
          </p>
          <p className=" w-full md:my-5 my-3 ">
            The day was filled with love, compassion, and emotions as the team
            of doctors and assistants worked tirelessly to provide care to over
            600 patients.
          </p>
          <p className=" w-full md:my-5 my-3 ">
            At the end of the day, the team enjoyed a beautiful lunch at the
            LOST NOMADS VILLAGE, having made a positive impact on the community.
          </p>
        </div>
      </div>
      {/*  */}
      <section className="md:mt-10 mt-4 container mx-auto p-4">
        <div className="md:flex items-center justify-center md:flex-row flex-col-1 gap-8 ">
          <div className="md:w-1/2 w-full md:p-8 p-4">
            <img
              className="mx-auto"
              src={`/images/programs/medicalimage.png`}
              alt=""
            />
          </div>
          <div className="md:w-1/2 w-full ">
            <h1 className="md:text-4xl text-xl font-bold">
              The objectives of the Medical caravan
            </h1>
            <div className="my-4">
              <ul className="list-image-check pl-8 md:pl-4">
                <li className="p-2  md:text-base text-sm">
                  Offering medical consultations and treatments to a large
                  patient population, numbering over 600 individuals.
                </li>
                {/*  */}
                <li className="p-2 md:text-base text-sm">
                  Mobilizing a team of over 30 medical professionals, including
                  doctors and assistants, to provide care.
                </li>
                {/*  */}
                <li className="p-2 md:text-base text-sm">
                  Ensuring that all necessary medicines and medical equipment
                  were readily available to provide comprehensive care to
                  patients.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default MedicalCaravanProgram;
