import React from 'react'

function AboutUs() {
  return (
    <div>
        <div className='md:h-[70vh] h-screen bg-no-repeat flex items-center justify-center bg-cover w-full bg-center' style={{backgroundImage:'url(.//images/aboutus/header.png)'}}>
            <div className='max-w-screen-xl mx-auto'>
                <h1 className='text-7xl  text-white  font-bold'>
                    About US
                </h1>
            </div>
        </div>
        <div className='flex pb-10 items-center justify-end pr-4'>
            <div className=' bg-primary h-20 flex items-center px-4 rounded-xl shadow-sm gap-4 -mt-10'>
                <div>
                    <span className='text-4xl font-bold'>400+</span> Beneficiaries
                </div>
                <div>
                    <span className='text-4xl font-bold'>50+</span> Projects
                </div>
            </div>
           
        </div>
        <div className='pt-10 max-w-screen-lg mx-auto p-4'>
            <div className='md:flex items-center justify-center md:flex-row flex-col-1 gap-8 '>
                <div className='md:w-1/2 p-8'>
                    <img src="/images/aboutus/about_us.png" alt="" />
                </div>
                <div className='md:w-1/2 '>
                    <h1 className='text-3xl font-bold'>We strive to create a better tomorrow for children.</h1>
                    <p>
                        Travel link foundation is a non-profit organization based in Morocco created in 2011.
                    </p>
                    <p>
                        Our mission is to raise awareness and find sustainable solutions over the most critical issues that directly affect the life in the Atlas Mountains villages.
                    </p>
                    <div className='pt-4'>
                       <ul className='list-image-check-green pl-8 md:pl-4'>
                            <li className='p-3'>
                                Fighting illiteracy.
                            </li>
                            <li className='p-3'>
                                School children support
                            </li>
                            <li className='p-3'>
                                Support girls who have missed their scholarship.
                            </li>
                       </ul>

                    </div>
                </div>
            

            </div>
        </div>

        <div className=' pt-10 max-w-screen-lg mx-auto p-4'>
            <h1 className='text-3xl font-bold'>Our objectives</h1>

            <div className='grid grid-cols-1 md:grid-cols-3 gap-4 pt-8'>
               <div className=' p-3'>
                    <div className='flex  justify-between'>
                        <div className=' flex flex-col justify-between'>
                            <div>
                                <img src="/images/aboutus/kid.png" alt="" />
                            </div>
                            <div className='text-2xl pt-4 font-semibold'>
                                Children
                            </div>
                        </div>
                        <div >
                            <img  src="/images/aboutus/1.png" alt="" />
                        </div>
                        
                    </div>
                   
                    <div className='opacity-70 pt-4'>
                        Ensure the right to education for all children in the High Atlas Mountains .
                    </div>
               </div>
               <div className=' p-3'>
                    <div className='flex  justify-between'>
                        <div className=' flex flex-col justify-between'>
                            <div>
                                <img src="/images/aboutus/women.png" alt="" />
                            </div>
                            <div className='text-2xl pt-4 font-semibold'>
                                Women
                            </div>
                            
                        </div>
                        <div  >
                            <img  src="/images/aboutus/2.png" alt="" />
                        </div>
                        
                    </div>
                   
                    <div className='opacity-70 pt-4'>
                        Women empowerment by creating adequate training centers.
                    </div>
               </div>
               <div className=' p-3'>
                    <div className='flex  justify-between'>
                        <div className=' flex flex-col justify-between'>
                            <div>
                                <img src="/images/aboutus/environment.png" alt="" />
                            </div>
                            <div className='text-2xl pt-4 font-semibold'>
                                Environment
                            </div>
                            
                        </div>
                        <div  >
                            <img  src="/images/aboutus/3.png" alt="" />
                        </div>
                        
                    </div>
                   
                    <div className='opacity-70 pt-4'>
                        Promoting sustainable tourism.
                    </div>
               </div>
               
            </div>

        </div>

        <div className='pt-10 max-w-screen-lg mx-auto p-4' >
            <h1 className='text-3xl font-bold'>Our partners</h1>

            <div className='pt-4'>
                <div className='grid grid-cols-1 md:grid-cols-3 gap-4' >
                    <div className='relative'>
                        <div className='absolute top-1/2 text-5xl -translate-x-1/2 left-1/2 -translate-y-1/2'>
                            Together
                        </div>
                        <img src="/images/aboutus/sq_red.png" alt="" />
                    </div>
                    <div className='relative'>
                        
                        <div className='absolute whitespace-nowrap text-white text-center  -translate-x-1/2 left-1/2  bottom-5'>
                            <h1 className='font-semibold'>
                                SOROPTIMIST INTERNATIONAL
                            </h1>
                            <p className='text-sm'>
                                Marigha Rural learning Center
                            </p>
                        </div>

                        <img src="/images/aboutus/soroptimist.png" alt="" />
                    </div>
                    <div className='relative'>
                        <div className='absolute top-1/2 text-5xl -translate-x-1/2 left-1/2 -translate-y-1/2'>
                            we
                        </div>
                        <img src="/images/aboutus/sq_blue.png" alt="" />
                    </div>
                    <div className='relative'>
                       
                        <div className='absolute whitespace-nowrap text-white text-center  -translate-x-1/2 left-1/2  bottom-5'>
                            <h1 className='font-semibold'>
                                CHILDREACH INTERNATIONAL
                            </h1>
                            <p className='text-sm'>
                                Ouarialt Rural learning Center
                            </p>
                        </div>
                        <img src="/images/aboutus/childreach.png" alt="" />
                    </div>
                    <div className='relative'>
                        <div className='absolute top-1/2 text-5xl -translate-x-1/2 left-1/2 -translate-y-1/2'>
                            Can
                        </div>
                        <img src="/images/aboutus/sq_blue.png" alt="" />
                    </div>
                    <div className='relative'>
                        
                        <div className='absolute whitespace-nowrap text-white text-center  -translate-x-1/2 left-1/2  bottom-5'>
                            <h1 className='font-semibold'>
                                SAGA CHARITABLE FOUNDATION
                            </h1>
                            <p className='text-sm'>
                                Agounsane wonen's learning Center
                            </p>
                        </div>
                        <img src="/images/aboutus/saga.png" alt="" />
                    </div>
                    <div className='relative'>
                        <div className='absolute text-5xl top-1/2 -translate-x-1/2 left-1/2 -translate-y-1/2'>
                            go
                        </div>
                        <img src="/images/aboutus/sq_blue.png" alt="" />
                    </div>
                    <div className='relative'>
                        <div className='absolute whitespace-nowrap text-white text-center  -translate-x-1/2 left-1/2  bottom-5'>
                            <h1 className='font-semibold'>
                                Dr KLEIN & Co. AG GERMANY
                            </h1>
                            <p className='text-sm'>
                                Taourirt Rural learning Center
                            </p>
                        </div>
                        <img src="/images/aboutus/dr-klein.png" alt="" />
                    </div>
                    <div className='relative'>
                        <div className='absolute top-1/2 text-5xl -translate-x-1/2 left-1/2 -translate-y-1/2'>
                            Further
                        </div>
                        <img src="/images/aboutus/sq_red.png" alt="" />
                    </div>

                </div>
            </div>
        </div>

        <div className='pt-10 max-w-screen-lg mx-auto p-4'>
            <h1 className='text-3xl font-bold'>They trust us</h1>

            <div className='pt-8'>
                <div className="grid grid-cols-2 md:grid-cols-5 gap-8">
                    <img src="/images/aboutus/soroptimist-international.png" alt="" srcset="" />
                    <img src="/images/aboutus/british_moroccan_society.png" alt="" srcset="" />
                    <img src="/images/aboutus/childreach-international.png" alt="" srcset="" />
                    <img src="/images/aboutus/odysseys-unlimited.png" alt="" srcset="" />
                    <img src="/images/aboutus/saga-charitable-foundation.png" alt="" srcset="" />
                  
                </div>

            </div>

        </div>

    </div>
  )
}

export default AboutUs