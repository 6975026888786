import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

function Header() {

    const location = useLocation()
    const [isHeader, setIsHeader] = useState(false)
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        if (location.pathname.split("/").length ===3 && location.pathname.split("/")[1]==="projects")
        setIsHeader(true)
   
    }, [location.pathname])
    

    return (
         
        <div className={`fixed px-4 bg-white z-40 top-0 right-0 left-0  py-2 text-black `}>
            <div className="p-2  md:flex justify-between items-center">
                <div className="flex items-center">
                    <div onClick={()=> setIsMobile(true)} className='md:hidden   block pr-2 '>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                        </svg>

                    </div>
                    <a href="/">
                        <img src="/logo.png" className=' z-10' width={"160px"} alt="" />
                    </a>
                
                </div>
                {/* desktop */}
                <ul className='md:flex hidden gap-x-3 text-sm lg:text-xl  items-center'>
                    <li >
                        <a href="/">Home</a>
                    </li>
                    <li >
                        <a href="/about-us">About us</a>
                    </li>
                    <li >
                        <a href="/programs">Our programs</a>
                    </li>
                    <li >
                        <a href="/projects">Our projects</a>
                    </li>
                    <li >
                        <a href="/members">Our members</a>
                    </li>
                    <li >
                        <a href="/gallery">Gallery</a>
                    </li>

                    <li>
                        <a href="/contact-us">
                            <button className='p-2 rounded-full bg-primary'>Contact Us</button>
                        </a>
                    </li>
                </ul>

                {/* mobile */}

            
                <ul className={` ${isMobile ? "block":"hidden" } md:hidden z-40 fixed top-0 pt-20 right-0 left-0 bottom-0 flex flex-col  backdrop-blur-lg gap-y-3 bg-white  bg-opacity-90 text-black   w-screen h-screen items-center`}>
                    <div className='absolute top-10 right-10'>
                        <button onClick={()=> setIsMobile(false)}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>

                    </div>
                    <li >
                        <a href="/">Home</a>
                    </li>
                    <li >
                        <a href="/about-us">About us</a>
                    </li>
                    <li >
                        <a href="/programs">Our programs</a>
                    </li>
                    <li >
                        <a href="/projects">Our projects</a>
                    </li>
                    <li >
                        <a href="/members">Our members</a>
                    </li>
                    <li >
                        <a href="/gallery">Gallery</a>
                    </li>

                    <li>
                        <a href="/contact-us">
                            <button className='p-2 rounded-full bg-primary'>Contact Us</button>
                        </a>
                    </li>
                </ul>
            


        
                {/* <div>
                    <button className="bg-white py-1 rounded-full px-8">
                        App
                    </button>
                </div> */}
                
            </div>

        </div>
        
    )
}

export default Header
