import React from "react";
import "./App.css";
import Home from "pages/Home";
import Footer from "layout/Footer";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Programs from "pages/Programs";
import Projects from "pages/Projects";
import AboutUs from "pages/AboutUs";
import Members from "pages/Members";
import Gallery from "pages/Gallery";
import Header from "layout/Header";
import ContactUs from "pages/ContactUs";
import ProjectDetails from "pages/ProjectDetails";
import EarthquakeProgram from "pages/EarthquakeProgram";
import MedicalCaravanProgram from "pages/MedicalCaravanProgram";

function App() {
  return (
    <div className="font-light">
      <Router>
        <Header />
        <Route path={"/"} exact component={Home} />
        <Route path={"/programs/"} exact component={Programs} />
        <Route
          path={"/programs/earthquake/"}
          exact
          component={EarthquakeProgram}
        />
        <Route
          path={"/programs/medical-caravan/"}
          exact
          component={MedicalCaravanProgram}
        />
        <Route path={"/projects/"} exact component={Projects} />
        <Route path={"/projects/:slug"} exact component={ProjectDetails} />

        <Route path={"/about-us/"} exact component={AboutUs} />
        <Route path={"/members/"} exact component={Members} />
        <Route path={"/gallery/"} exact component={Gallery} />
        <Route path={"/contact-us/"} exact component={ContactUs} />
        {/* <Route path={"/*"} exact component={NotFound} /> */}

        <Footer />
      </Router>
      {/* <Home />  */}
    </div>
  );
}

export default App;
