import React from "react";
import uuid from "react-uuid";

const members = [
  {
    name: "Ahmed NAIT",
    role: "President",
    image: "images/home/members/Ahmed_NAIT_TAADOUIT.png",
  },
  {
    name: "Othman Nait",
    role: "Vice President",
    image: "images/home/members/Abdelkrim_OUACHIKH.png",
  },
  {
    name: "Abdelkrim Ouachikh ",
    role: "Secretary General",
    image: "images/home/members/General_Treasurer.png",
  },
  {
    name: "Abderahim Loubnani",
    role: "General Treasurer",
    image: "images/home/members/Mohamed_BAAYOU.png",
  },
  {
    name: "Sarah Nait",
    role: "Deputy General Treasurer",
    image: "images/home/members/Mohamed_El_Bouchhati.png",
  },
];

const MemberItem = ({ member, index }) => {
  return (
    <div
      className={` ${
        index === 0 &&
        "md:row-span-2 md:flex md:flex-col md:justify-center items-center"
      } 'bg-white rounded-xl border shadow-sm '`}
    >
      {/* <img src={member.image} className={index ===0 ? "w-full aspect-video md:aspect-auto object-cover " :'aspect-video w-full object-cover object-top  rounded-t-xl'} alt="" /> */}

      <div className="p-4">
        <h1 className="text-xl font-bold ">{member.name}</h1>
        <p className="opacity-75">{member.role}</p>
      </div>
    </div>
  );
};

function Members() {
  return (
    <div className="pt-10 px-4 max-w-screen-lg mx-auto">
      <h1 className="text-3xl font-bold">Our members</h1>

      <div className="pt-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {members.map((member, index) => (
            <MemberItem index={index} key={uuid()} member={member} />
          ))}
        </div>

        <div className="py-4 flex items-center justify-center">
          <a href="/members">
            <button className="py-3 px-8 border hover:bg-gray-50 rounded-full">
              See More{" "}
            </button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Members;
