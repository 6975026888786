import React from 'react'

function ContactUs() {
  return (
    <div className='min-h-screen'>
        <div className='md:h-[70vh] h-screen  bg-no-repeat flex items-center justify-center bg-cover w-full bg-center' style={{backgroundImage:'url(./images/contactus/header.png)'}}>
            <div className='max-w-screen-xl mx-auto'>
                <h1 className='text-7xl  text-white  font-bold'>
                    Contact US
                </h1>
            </div>
        </div>

        <div className='max-w-screen-lg mx-auto'>
            
            <div className="md:flex gap-10 m-10 md:m-20 p-4 rounded-xl md:p-10 bg-primary  items-center justify-center">
                <div className='md:w-1/2'>
                    <h1 className='text-3xl font-bold'>Let's Connect</h1>
                    <form className='p-4 '>
                        <div className='border my-3 rounded-md border-gray-400'>
                            <input type="text" className='p-2  placeholder-gray-500 outline-none bg-transparent w-full' placeholder='full name'/>
                        </div>
                        <div className='border my-3 rounded-md border-gray-400'>
                            <input type="text" className='p-2  placeholder-gray-500 outline-none bg-transparent w-full' placeholder='email'/>
                        </div>
                        <div className='border my-3 rounded-md border-gray-400'>
                            <textarea  className='p-2  placeholder-gray-500  outline-none bg-transparent w-full' placeholder='comment'/>
                        </div>

                        <div className='pt-6'>
                            <button className='p-2 border-gray-500 text-white bg-black rounded-md'>Submit</button>
                        </div>
                    </form>
                </div>
                <div className='md:w-1/2'>
                    <div className='py-3'>
                        <strong>Phone : </strong> <br />
                        <p>
                            +212 661 148 451
                        </p>
                    </div>
                    <div className='py-3'>
                        <strong>Email : </strong> <br />
                        <p>
                            contact@travellinkfoundation.org 
                        </p>
                    </div>

                </div>
            </div>
            
            
        </div>

        
    </div>
  )
}

export default ContactUs